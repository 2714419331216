<template>
  <div class="row">
    <div class="col-xs-12">
      <page-header
        title="Packages"
        :sub-heading="true"
        sub-header="Dashboard"
        link-name="Packages"
        link-url="/packages"
      >
        <ca-button class="mr-2" @click.native="createPackage"
          >Create Package</ca-button
        >
      </page-header>

      <full-page-content-container>

            <ca-popup
        :header="'Delete User'"
        :toggle="openDeletePopup"
        @open="confirmDelete"
        @close="cancelDelete"
        v-show="currentPackage !== null"
      >
        <template v-slot:content>
          Are you sure you want to delete the user?
        </template>
        <template v-slot:actions>
          <ca-button @click.native="deletePackage">Yes</ca-button>
        </template>
      </ca-popup>

        <grid-table
          :row-data="rowData"
          :column-defs="columnDefs"
          v-if="rowData.length > 0"
        >
          <template v-slot:cell-renderer="slotProps">
            <span class="icon-button mr-1"
              ><img
                :src="require('@/assets/images/icons/edit.svg')"
                alt="view icon"
                @click="editPackage(slotProps.currentRowData.id)"
            /></span>
            <span class="icon-button"
              @click="confirmDelete(slotProps.currentRowData)"
              ><img
                :src="require('@/assets/images/icons/delete.svg')"
                alt="view icon"
            /></span>
          </template>
        </grid-table>
        <empty-dataset v-else></empty-dataset>
      </full-page-content-container>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import GridTable from "../../components/tables/GridTable";
import EmptyDataset from "../../components/displays/EmptyDataset";
import gridTableParentMixin from "../../../mixins/gridTableParentMixin";
import CaPopup from "../../components/popup/CaPopup";
import axios from "../../../axios";
export default {
  mixins: [gridTableParentMixin],
  name: "Packages",
  components: {
    EmptyDataset,
    GridTable,
    FullPageContentContainer,
    CaButton,
    PageHeader,
    CaPopup,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Name",
          field: "name",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Stripe ID",
          field: "package_stripe_id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Amount",
          field: "amount",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Date Created",
          field: "created_at",
          filter: true,
          sortable: true,
        },
        {
          headerName: "No Of Valuations",
          field: "no_of_companies",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/show-all-packages`,
      openDeletePopup: false,
      currentPackage: null,
    };
  },
  methods: {
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          loading.close();
          const response = res.data.data;
          console.log(response);
          // const success = response.success;
         
          this.rowData = response.package;
        })
        .catch((err) => {
          this.$toast(err.response.data.message, {
            type: "error",
            position: "top-center",
          });
          loading.close();
          console.log(err.response.data);
        });
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete(popUpData = null) {
      if (popUpData !== null) {
        console.log(popUpData);
        this.currentPackage = popUpData;
      }
      this.openDeletePopup = true;
    },

    editPackage(id) {
      this.$router.push({ name: "edit-package", params: { id } });
    },
    deletePackage() {
      const loading = this.$vs.loading();
      axios
        .delete(
          `${this.$store.state.server.requestUri}/admin/delete-package/${this.currentPackage.id}`
        )
        .then((res) => {
          this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
          loading.close();
          this.cancelDelete();
          this.$toast(
            `Package with id ${this.currentPackage.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.cancelDelete();
          this.$toast(`An error occurred while deleting package`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },
    createPackage() {
      this.$router.push({ name: "create-package" });
    },
  },

  created() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
  },
};
</script>

<style scoped></style>
