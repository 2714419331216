<template>
  <div class="ca-display">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyDataset",
  props: {
    message: {
      type: String,
      default: "Empty Data Set",
    },
  },
  created() {
    console.log("Rendered");
  },
};
</script>

<style scoped>
.ca-display {
  width: 100%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: var(--cl-white-3);
  color: var(--cl-primary);
}

p {
  font-size: 1rem;
  font-weight: 500;
}
</style>
